import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Main from '../components/ui/main';
import H1 from '../components/ui/h1';
import Section from '../components/ui/section';

class WhoPage extends React.Component {
  render() {
    return (
      <Layout>
        <Header />
        <SEO
          description="The Jeffrey Epstein Ranking provides a classification of Universities according to the donations received via the scientific broker Jeffrey Epstein."
          title="What is JER™: Pecunia non olet"
        />

       <Main>
          <Section>
            <H1>What is JER™: Pecunia non olet </H1>
            <div className="max-w-full mx-auto w-large">
              <p>With such a background, some will find it impossible (or even undesirable) that something like the JER™ could ever exist. Based on the naive assumption that
                no respectable university would accept money channelled through Epstein given his role in such murky matters, they will wonder: What could be worse for any
                educational institution than lending itself to be part of the maneuvers of reputation (not to mention money) laundering of an alleged trafficker of girls
                for sexual exploitation?
              </p>
              <p>
                Nevertheless, anyone who thinks so will be getting it wrong: the competitiveness of the global university market turns those Pollyannaish scruples futile.
                Furthermore, for several élite universities Epstein showed throughout these past years a much kinder face, that of a generous provider of funds (both own and
                third-party capital) for their cutting edge scientific programs and equipments, donations usually granted with a 100% tax exemption in USA.
              </p>
              <p>
                This reference to taxation provided me with the moral key to overcome my initial misgivings and convince myself to capitalize Epstein's philanthropic work
                to forge my JER™. Indeed, if we dust down the classics, we will surely remember how Roman emperor Vespasian responded to his son Titus’s complaints about
                the tax imposed to tanners and launderers for using urine collected from the Roman Cloaca Maxima (Greatest Sewer): “Pecunia non olet” (“Money does not smell”).
              </p>
              <p>Inspired by this clo-academic maxim, the JER™ ranking quantifies the pecunary smell of élite universities funded by Jeffrey Epstein with a view to
                contributing to the “total bureaucratization” that stems from the “Iron Law of Liberalism” (Graeber, 2015: 3–44). In my view, this is particularly important
                in Europe, where universities still lag behind USA, because of their constraining conception of public education as an universal right, of public scrutiny,
                of students’ and workers’ right to participation or of the universal suffrage itself (even with weighted voting) for the election of academic leaders.
                Nevertheless, the greatest market distortion keeps coming from public subsidies, which still allows to get a college degree or even a master’s degree for
                free in many European countries.
              </p>
              <p>In short, my JER™ ranking is aimed to provide the university and philantropic executives with a classification of higher education entities (American
                for the most part) according to the donations received via the scientific broker Jeffrey Epstein. Such classification not only takes into account the total
                amount of gifts, but breaks them down to show the quantities accepted before and after Epstein’s pedophile conviction in 2008. Wherever possible, the JER™
                also provides information about donor and recipient institutions, date of donations, return of funds (when appropriate) and the reflection of all this in
                Epstein's philanthropic websites.
              </p>
              <p>
                Next: <Link to="/how-is-jer-assessed">How is JER™ assessed</Link>
              </p>
          </div>
          </Section>
        </Main>
      </Layout >
    )
  }
}

export default WhoPage
